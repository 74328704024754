import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Autocomplete, Button, Chip, ClickAwayListener, Skeleton, TextField } from "@mui/material"
import { ArrowDropDown as ArrowDropDownIcon, Person as PersonIcon } from "@mui/icons-material"
import styled from "@emotion/styled"

import { ErrorContext, SuccessContext } from "../../helper/AlertContext"
import BasicSwitch from "../items/Toggle"
import { appService, datasetService, organizationService } from "../../api/services"
import { WORKSPACE_ROLES } from "../../utils/constants"

const ActionMenu = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  position: absolute;
  right: 0;
  top: 1.5rem;
  width: 10rem;
  z-index: 11;
  button {
    display: block;
    padding: 0.5rem 1rem;
    text-align: left;
    text-transform: capitalize;
    width: 100%;
  }
`
const UserRow = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 26px 1fr 100px;
  gap: 8px;
  padding-bottom: 1rem;
  text-align: left;
  img {
    height: 26px;
    object-fit: cover;
    border-radius: 50%;
  }
  > div:nth-of-type(1) {
    overflow: hidden;
  }
  > div:nth-of-type(2) {
    text-align: right;
  }
`
const FormTitle = styled.h4`
  margin: 0 0 12px;
`
const RoleButton = styled(Button)`
  min-width: 0;
  padding: 0;
  text-transform: capitalize;
`

const ShareResourceForm = ({ formData, onChange = () => {}, refresh = () => {} }) => {
  const [accessState, setAccessState] = useState(false)
  const [actionMenuState, setActionMenuState] = useState({ show: false, type: "" })
  const [memberList, setMemberList] = useState([])
  const [sharedUserList, setSharedUserList] = useState([])
  const [usersInput, setUsersInput] = useState([])
  const { setSuccess, setSuccessMsg } = useContext(SuccessContext)
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const { app_id: appId, dataframeId } = useParams()
  const pageType = dataframeId ? "dataset" : "project"
  const accessConfig = {
    project: {
      deleteRequest: appService.removeProjectAccessUser,
      id: appId,
      ownerKey: "app_owner",
    },
    dataset: {
      deleteRequest: datasetService.removeDatasetAccessUser,
      id: dataframeId,
      ownerKey: "dataframe_owner",
    },
  }

  const handleGetMemberList = async () => {
    const { data } = await organizationService.getMemberList()
    setMemberList(
      data.data.map((item) => ({ name: `${item.first_name} ${item.last_name}`, email: item.email, id: item.id })),
    )
  }
  const handleRemoveAccessUser = async (type, userId) => {
    const { deleteRequest } = accessConfig[type]

    try {
      await deleteRequest(accessConfig[type].id, userId)
      setSuccess(true)
      setSuccessMsg(`User removed from the ${type}.`)
      refresh()
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    }
  }
  const toggleActionMenu = (type = "") => {
    const { show } = actionMenuState
    setActionMenuState({ show: !show, type })
  }

  useEffect(() => {
    onChange({ all: accessState, users: usersInput.map((user) => ({ id: user.id })) })
  }, [accessState, usersInput])
  useEffect(() => {
    if (Object.keys(formData).length) {
      const { ownerKey } = accessConfig[pageType]

      setAccessState(formData.all)
      setSharedUserList([
        {
          avatar: formData[ownerKey].avatar_url,
          id: formData[ownerKey].id,
          name: `${formData[ownerKey].first_name} ${formData[ownerKey].last_name}`,
          email: formData[ownerKey].email,
          role: "Creator",
        },
        ...formData.users.map((user) => ({
          avatar: user.avatar_url,
          id: user.id,
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          role: WORKSPACE_ROLES[user.group_name] || user.group_name,
        })),
      ])
    }
  }, [formData])

  useEffect(() => {
    handleGetMemberList()
  }, [])

  return (
    <div>
      <FormTitle style={{ marginTop: 0 }}>Users</FormTitle>
      <div style={{ marginBottom: 26 }}>
        <Autocomplete
          multiple
          disableClearable
          getOptionLabel={(option) => option.email}
          filterOptions={(options, { inputValue }) => {
            const filterValue = inputValue.toLowerCase()
            return options.filter(
              (option) =>
                option.name.toLowerCase().includes(filterValue) || option.email.toLowerCase().includes(filterValue),
            )
          }}
          onChange={(_, value) => {
            setUsersInput(value)
          }}
          options={memberList.filter((member) => !sharedUserList.some((user) => user.id === member.id))}
          renderInput={(params) => (
            <TextField {...params} placeholder={usersInput.length ? "" : "Enter name or email"} />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <div style={{ fontSize: "0.7rem" }}>
                <div>{option.name}</div>
                <div>{option.email}</div>
              </div>
            </li>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { ...tagProps } = getTagProps({ index })
              return <Chip variant="outlined" label={option.email} key={option.email} {...tagProps} />
            })
          }
          value={usersInput}
        />
      </div>
      {accessState ? null : (
        <>
          <FormTitle>People with Access</FormTitle>
          <div style={{ marginBottom: 10, textAlign: "left" }}>
            {sharedUserList?.length
              ? sharedUserList.map((user, index) => (
                  <UserRow key={user.email}>
                    {user.avatar ? <img src={user.avatar} alt={user.name} /> : <PersonIcon />}
                    <div>
                      <div>
                        {user.name} {index === 0 && "(You)"}
                      </div>
                      <div>{user.email}</div>
                    </div>
                    <div style={{ position: "relative" }}>
                      {index === 0 ? (
                        <span>{user.role}</span>
                      ) : (
                        <>
                          <RoleButton onClick={() => toggleActionMenu(`actionMenu-${user.id}`)} variant="text">
                            {user.role}
                            <ArrowDropDownIcon />
                          </RoleButton>
                          {actionMenuState.show && actionMenuState.type === `actionMenu-${user.id}` ? (
                            <ClickAwayListener onClickAway={toggleActionMenu}>
                              <ActionMenu>
                                <Button
                                  onClick={() => {
                                    handleRemoveAccessUser(pageType, user.id)
                                  }}
                                  color="error"
                                >
                                  Remove
                                </Button>
                              </ActionMenu>
                            </ClickAwayListener>
                          ) : null}
                        </>
                      )}
                    </div>
                  </UserRow>
                ))
              : "-"}
          </div>
        </>
      )}
      <FormTitle>General Access</FormTitle>
      <div style={{ display: "flex", alignItems: "center", marginLeft: -12, marginTop: -4 }}>
        <BasicSwitch defaultChecked={accessState} handleSave={setAccessState} />
        <span>Make Available to All Team Members</span>
      </div>
    </div>
  )
}

export default ShareResourceForm
